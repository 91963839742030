import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../../../hoc/withAuthentication"
import { AccountAuth as Page } from "../../../components/Account/Login/AccountAuth"

export const query = graphql`
  query {
    page: sanityPageAccountLogin {
      title
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

export default withAuthentication(({ data, ...props }) => <Page {...props} {...data} />)
