import React, { useEffect, useState } from "react"

import { useCheckout } from "../../../hooks/useCheckout"
import { useCustomerContext } from "../../../hooks/useCustomer"

export const withAccountAuth = Component => ({ name = `AccountAuth` }) => {
  const LOADING_STATES = ["Verifying Account...", "Loading Customer Details...", "Completing Login..."]
  const [status, setStatus] = useState(LOADING_STATES[0])
  const { customer } = useCustomerContext()
  const { gotoCheckout } = useCheckout()

  useEffect(() => {
    if (customer?.id) {
      gotoCheckout()
    }
  }, [location, customer, gotoCheckout])

  useEffect(() => {
    setTimeout(() => {
      setStatus(LOADING_STATES[1])
      setTimeout(() => {
        setStatus(LOADING_STATES[2])
      }, 3000)
    }, 2000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  Component.displayName = name
  return (
    <Component
      status={status}
    />
  )
}
