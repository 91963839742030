import React from "react"

import { withAccountAuth } from "./withAccountAuth"

import { ColumnLoading, Row, StyledContainer } from "./AccountLoginStyles"
import { FormHeading } from "../../Styled/Form"

export const AccountAuth = withAccountAuth(({status}) => (
  <StyledContainer>
    <Row>
      <ColumnLoading>
          <FormHeading>{status}</FormHeading>
      </ColumnLoading>
    </Row>
  </StyledContainer>
))

